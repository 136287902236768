up.compiler('[up-enhance-parent]', (element) => {
  var definition = JSON.parse(element.getAttribute('up-enhance-parent'));
  var enhancable = element.closest(definition['parent']);
  if(enhancable === null){ return; }

  if(Object.keys(definition).includes('add')){
    for(var attr of Object.keys(definition['add'])){
      var enhanced = '';
      if(enhancable.getAttribute(attr) != null){
        enhanced += enhancable.getAttribute(attr) + ' ';
      }
      enhanced += definition['add'][attr];
      enhancable.setAttribute(attr, enhanced);
    }
  }
});
