import { Dismiss } from 'flowbite';
const options = {
  transition: 'transition-opacity',
  duration: 1000,
  timing: 'ease-out',

  // callback functions
  // onHide: (context, targetEl) => {
  //   console.log('element has been dismissed')
  //   console.log(targetEl)
  // }
};
up.compiler('[up-dismiss]', (element) => {
  setTimeout(()=>{element.remove()}, parseInt(element.getAttribute('up-dismiss')));
});