up.compiler(".reload-counter", (element) => {
  function countdown(){
    var counter = parseInt(element.innerText);
    counter = counter - 1;
    element.innerText = counter;
    setTimeout(countdown, 1000);
  }

  countdown(element);
});

