up.compiler(".matomo", (element) => {
  var u="//analytics.tasler.it/";
  var _paq = window._paq = window._paq || [];
  _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
  _paq.push(["setCookieDomain", "*.summarize.one"]);
  _paq.push(["setDomains", ["*.summarize.one","*.summarize.one","*.www.summarize.one"]]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  _paq.push(['setTrackerUrl', u+'matomo.php']);
  _paq.push(['setSiteId', '2']);

  loadMatomoTracker();

  // enable tracking of unpoly page loads:
  up.on('up:location:changed', function(event) {
    _paq.push(['setCustomUrl', location.pathname]);
    _paq.push(['trackPageView']);
  });
});

function loadMatomoTracker(){
  var script = document.createElement('script');
  script.src = "https://analytics.tasler.it/matomo.js";
  document.getElementsByTagName('head')[0].appendChild(script);
}

up.compiler("[trackGoal]", (element) => {
  if (typeof _paq !== 'undefined') {
    _paq.push(["trackGoal", parseInt(element.getAttribute('trackGoal'))])
  }
});
