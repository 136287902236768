import {Turbo} from "@hotwired/turbo-rails"
Turbo.session.drive = false

import 'unpoly'
import 'chartkick/chart.js'
import './utils/datetimerange-picker'

import './compilers/flowbite_init_compiler'
import './compilers/matomo_compiler'
import './compilers/dismiss_controller'
import './compilers/date_range_compiler'
import './compilers/datepicker_compiler'
import './compilers/qr_compiler'
import './compilers/slider_compiler'
import './compilers/spinner_compiler'
import './compilers/toggle_switch_compiler'
import './compilers/parent_element_enhancer_compiler'
import './compilers/confetti_compiler'
import './vendor/unpoly-init'
import './vendor/flowbite-init'
import './compilers/reload_counter_compiler'
import './compilers/special_compilers'
import './compilers/input_compiler'
//import './utils/flowbite_popover_fix'
