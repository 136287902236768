up.compiler("select option[value='']", (element) => {  
  element.classList.add('text-gray-400', 'italic');
})

up.compiler("li > input.search-dropdown", (element) => {
  var list = element.parentElement.nextElementSibling.querySelectorAll('.dropdown-select-item');
  element.addEventListener('input', function() {
    var value = this.value.toLowerCase();
    list.forEach((item) => {
      item.style.display = item.textContent.toLowerCase().includes(value) ? 'flex' : 'none';
    });
  });
})
