import moment from 'moment'
up.compiler('input.daterange-picker', element => {
  let server_value = element.getAttribute('value'); // we need to save the server input value, because daterangepicker set default value on auto update mode

  let nonce =  randomString(5);
  element.setAttribute('up-daterangepicker', nonce);
  let picker = new window.DateRangePicker(element.getAttribute('id'), 
  {
    minYear: 2022,
    maxYear: 2100,
    showWeekNumbers: true,
    linkedCalendars: false,
    alwaysShowCalendars: true,
    //parentEl: document.querySelector('main'),
    opens: "center",
    ranges: {
      'last 7 days': [moment().subtract(6, 'days'), moment()],
      'last 30 days': [moment().subtract(29, 'days'), moment()],
      'last 365 days': [moment().subtract(365, 'days'), moment()],
      'current month': [moment().startOf('month'), moment().endOf('month')],
      'last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'current year': [moment().startOf('year'), moment().endOf('year')],
      'last year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
    },
    locale: {
        format: 'DD.MM.YYYY',
        applyLabel: "Apply",
        cancelLabel: "Reset",
        customRangeLabel: "custom",
        daysOfWeek: [
            "So",
            "Mo",
            "Tu",
            "We",
            "Th",
            "Fr",
            "Sa"
        ],
        monthNames: [
            "Jannuary",
            "Feburary",
            "March",
            "April",
            "Mai",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ],
        firstDay: 1,
    },
  });

  picker.container.setAttribute('up-daterangepicker', nonce);
  
  
  window.addEventListener('apply.daterangepicker', function (ev) {
    if(ev.detail.element.id == 'filter_date_interval') {
      target = undefined;
      if(ev.detail.element.hasAttribute('up-target')){
        target = ev.detail.element.getAttribute('up-target');
      } else if (ev.detail.element.form.hasAttribute('up-target')){
        target = ev.detail.element.form.getAttribute('up-target');
      }

      up.submit(ev.detail.element.form, { target: target })
    }
  });

  window.addEventListener('cancel.daterangepicker', function (ev) {
    if(ev.detail.element.id == 'filter_date_interval') {
      picker.setStartDate(moment().startOf('year'));
      picker.setEndDate(moment().endOf('year'));
    }
  });

  document.querySelectorAll('.daterangepicker').forEach( (p) => {
    let dep = document.querySelector(`input[up-daterangepicker="${p.getAttribute('up-daterangepicker')}"`);
    if(dep === undefined || dep == null){
      p.remove();
    }
  });

});

var randomString = function(length) {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for(var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}