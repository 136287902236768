up.compiler("input.range-slider", (element) => {
  element.style.setProperty('--value', element.getAttribute('value'));
  element.style.setProperty('--min', element.getAttribute('min'));
  element.style.setProperty('--max', element.getAttribute('max'));

  let sliderTooltip = element.parentElement.parentElement.querySelector('#'+element.getAttribute('data-tooltip-target'));
  sliderTooltip.style.position="absolute";

  let sliderMin = element.getAttribute('min');
  let sliderMax = element.getAttribute('max');

  element.addEventListener('input', (event) => {
    let val = event.target.value;

    event.target.style.setProperty('--value', val);
    if(element.classList.contains('range-slider-as-percent')){
      val = `${val*100}%`;
    }
    sliderTooltip.querySelector('.slider-tooltip-content').innerHTML = val;

    let sliderPositionInfo = element.getBoundingClientRect();
    let offset = (sliderPositionInfo.width / (sliderMax-sliderMin) * (val-sliderMin));
    let pos = ((sliderPositionInfo.width/2)*-1) + offset;
    sliderTooltip.style.left = `${pos}px`;
  });

  if(element.classList.contains('range-slider-as-percent')){
    sliderMin = `${sliderMin*100}%`;
    sliderMax = `${sliderMax*100}%`;
  }

  element.parentElement.setAttribute("slider-min", sliderMin.toString());
  element.parentElement.setAttribute("slider-max", sliderMax.toString());
});