import qrcodegen from "@ribpay/qr-code-generator";
import { toSvgString } from "@ribpay/qr-code-generator/utils";

up.compiler('.render.qr-code', (element) => { 
  // Name abbreviated for the sake of these examples here
  const QRC = qrcodegen.QrCode;

  // Simple operation
  const qr0 = QRC.encodeText(element.getAttribute('value'), QRC.Ecc.MEDIUM);
  element.innerHTML = toSvgString(qr0, 0, "transparent", "currentColor") + element.innerHTML;

  element.setAttribute('value', '');
  element.classList.remove('render');
  
});

