import queryString from 'query-string'

up.compiler('.auto-update-link', (element) => {
  target = document.getElementById(element.getAttribute('target'));
  target.addEventListener('click', function(event) {
    event.preventDefault();
    var url = decodeURIComponent(target.getAttribute('href'));
    window.open(url, '_blank');
  });

  element.addEventListener('input', (event) => {
    q = queryString.parse(target.getAttribute('href'));
    q.wa_id = element.value;
    target.setAttribute('href', queryString.stringify(q));
    if(q.wa_id != undefined && q.wa_id != '' && /^[0-9]+$/.test(q.wa_id)){
      target.removeAttribute('disabled')
    } else {
      target.setAttribute('disabled', 'disabled')
    }
  });
});
