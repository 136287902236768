import confetti from "canvas-confetti";
up.compiler('.confetti-canon', (element => {
  element.addEventListener('click', (event) => {
    let canon_direction = 90; 
    if (element.classList.contains('canon-up')){
      canon_direction = 90
    } else if (element.classList.contains('canon-left')){
      canon_direction = 180
    } else if (element.classList.contains('canon-right')){
      canon_direction = 0
    } else if (element.classList.contains('canon-down')){
      canon_direction = 270
    }

    const angle = Math.random() * canon_direction;
    const drift = 0;

    const { top, height, left, width } = element.getBoundingClientRect();
    const x = (left + width / 2) / window.innerWidth;
    const y = (top + height / 2) / window.innerHeight;
    const origin = { x, y };

    confetti({
      origin,
      angle,
      particleCount: 75 + Math.random() * 50,
      spread: 50 + Math.random() * 50,
      drift,
      scalar: 1.3,
      zIndex: 1056, // Bootstrap Modal is 1055
      colors: [
        "#0d6efd",
        "#0fdd42",
        "#408458",
        "#4923BA",
        "#5BC8EC",
        "#C54482",
        "#CC444A",
        "#EE8437",
        "#F7C144",
        "#FFFD54",
      ],
    });
  })
}));
